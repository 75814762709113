export enum CompanyDirectorsStructure {
  SOLE_DIRECTOR = 'SOLE_DIRECTOR',
  MULTI_DIRECTORS = 'MULTI_DIRECTORS',
}

export enum UserStatus {
  UNVERIFIED = 'UNVERIFIED',
  LIVE = 'LIVE',
}

export enum UserGroupRelation {
  TRUSTEE = 'TRUSTEE',
  OFFICER = 'OFFICER',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  TRUST_OWNER = 'TRUST_OWNER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum UserGroupType {
  BUSINESS = 'BUSINESS',
  INDIVIDUALS = 'INDIVIDUALS',
}
